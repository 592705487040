import { type ComponentPropsWithoutRef } from "react"
import { mergeClasses } from "../../../../utils/css"
import { DivotTooltip } from "../../molecules/DivotTooltip/DivotTooltip"
import { Typography } from "../Typography"
import type { DivotTooltipProps } from "../../molecules/DivotTooltip/DivotTooltip"
import "./Tooltip.scss"

interface TooltipProps extends ComponentPropsWithoutRef<"div"> {
    /**
     * Text to display in the tooltip
     */
    text?: string
    /**
     * Additional classes to provide to the inner div
     */
    className?: string
    /**
     * Props to pass to the divot tooltip component
     */
    divotProps?: DivotTooltipProps
}
/**
 * A styled tooltip component. Should be used with `useTooltip` to trigger it from another element.
 *
 * @example
 * ```jsx
 * const ComponentWithTooltip = () => {
 *   const ref = useRef(null)
 *   const { isOpen } = useTooltip(ref)
 *
 *   return (
 *     <div style={{ position: "relative" }}>
 *       <div ref={ref}>I'll trigger a tooltip on hover</div>
 *       {isOpen && <Tooltip text="I am some text that will display in the tooltip" />}
 *     </div>
 *   )
 * }
 * ```
 */
export const Tooltip = ({
    text,
    children = text,
    className,
    divotProps,
    ...props
}: TooltipProps) => {
    return (
        <>
            {divotProps && <DivotTooltip {...divotProps} />}
            <Typography
                size="xs"
                className={mergeClasses("cbr-tooltip", className)}
                role="tooltip"
                {...props}
            >
                {children}
            </Typography>
        </>
    )
}
