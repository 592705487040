import { type ComponentPropsWithoutRef, useRef } from "react"
import "./InfoIconTooltip.scss"
import { useTooltip } from "../../../../hooks/useTooltip"
import { mergeClasses } from "../../../../utils/css"
import { Info } from "../../atoms/Icons/Others"
import { Tooltip } from "../../atoms/Tooltip"
import type { DivotTooltipProps } from "../DivotTooltip/DivotTooltip"

interface InfoIconTooltipProps extends ComponentPropsWithoutRef<"div"> {
    text?: string
    tooltipClassName?: string
    divotProps?: DivotTooltipProps
}

export function InfoIconTooltip({
    text,
    tooltipClassName,
    className,
    divotProps,
    children,
    ...props
}: InfoIconTooltipProps) {
    const ref = useRef(null)
    const { isOpen } = useTooltip(ref)
    return (
        <div
            className={mergeClasses("cb-info-icon-tooltip", className)}
            {...props}
        >
            <Info ref={ref} />
            {isOpen && (
                <>
                    <Tooltip
                        text={text}
                        className={mergeClasses(
                            "cb-info-icon-tooltip__ttcontent",
                            tooltipClassName,
                        )}
                        divotProps={divotProps}
                    >
                        {children}
                    </Tooltip>
                </>
            )}
        </div>
    )
}
