import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/query"
import messagingReducer, { messagingApi } from "./messagingSlice"
import purchasePageReducer, {
    purchasePageApi,
} from "./purchasePage/purchasePageSlice"
import roomReducer from "./roomSlice"
import noteReducer, { notesApi } from "./userList/notesSlice"
import userReducer from "./userSlice"

const rootReducer = combineReducers({
    [notesApi.reducerPath]: notesApi.reducer,
    [purchasePageApi.reducerPath]: purchasePageApi.reducer,
    [messagingApi.reducerPath]: messagingApi.reducer,
    purchasePage: purchasePageReducer,
    user: userReducer,
    notes: noteReducer,
    messaging: messagingReducer,
    room: roomReducer,
})
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setupStore = (preloadedState?: RootState) =>
    configureStore({
        reducer: rootReducer,
        preloadedState: preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat([
                notesApi.middleware,
                purchasePageApi.middleware,
                messagingApi.middleware,
            ]),
    })
export const store = setupStore(
    (window as any).$reactAppContext
        ? ({
              user: {
                  languageCode: (window as any).$reactAppContext.language_code,
                  loggedInUser: (window as any).$reactAppContext.logged_in_user,
              },
          } as RootState)
        : undefined,
)

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore["dispatch"]
