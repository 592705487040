import type React from "react"

interface IKeyboardHandler {
    keyCode: number
    requiresCtrlOrMeta: boolean
    handle: (event: React.KeyboardEvent | KeyboardEvent) => void
}

export class KeyboardEventHandlerChain {
    private handlers: IKeyboardHandler[] = []

    addHandler(handler: IKeyboardHandler): void {
        this.handlers.push(handler)
    }

    execute(event: React.KeyboardEvent | KeyboardEvent): boolean {
        for (const handler of this.handlers) {
            if (
                event.which === handler.keyCode &&
                (!handler.requiresCtrlOrMeta || event.ctrlKey || event.metaKey)
            ) {
                handler.handle(event)
                return true
            }
        }
        return false
    }
}
