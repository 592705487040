import { useRef } from "react"
import { t } from "@lingui/macro"
import { isMobile } from "react-device-detect"
import { Sizes, Weights } from "../../../../interfaces/sizing"
import Button, { ButtonColor } from "../../atoms/Button"
import "./CommonLowBalanceToast.scss"
import { Typography } from "../../atoms/Typography"

interface CommonLowBalanceToastProps {
    numTokens: string
    openPuchasePage: ({
        source,
        roomType,
        target,
    }: {
        source: string
        roomType: string
        target: HTMLElement
    }) => void
    closeToast?: () => void
}

export const CommonLowBalanceToast = ({
    openPuchasePage,
    numTokens,
    // passed by toast function
    closeToast,
}: CommonLowBalanceToastProps) => {
    const buttonRef = useRef(null)
    return (
        <div className="CommonLowBalanceToast">
            {!isMobile && (
                <Typography
                    size={Sizes.mdpx}
                    weight={Weights.bold}
                    className="CommonLowBalanceToast__title"
                >
                    {t`Low token balance`}
                </Typography>
            )}
            <div className="CommonLowBalanceToast__info">
                <Typography size={Sizes.xspx}>
                    {t`Hi there! Your token balance is getting low. `}
                </Typography>
                <Typography size={Sizes.xspx}>
                    {t`Recharge to continue the fun.`}
                </Typography>
                {isMobile && (
                    <Typography
                        size={Sizes.xspx}
                        className="CommonLowBalanceToast__secondaryText"
                    >
                        {t`You have: ${numTokens} tokens`}
                    </Typography>
                )}
            </div>
            <div className="CommonLowBalanceToast__purchButtonWrapper">
                <Button
                    ref={buttonRef}
                    color={ButtonColor.Blue}
                    text="Add Tokens"
                    className="CommonLowBalanceToast__purchaseButton"
                    onClick={() => {
                        openPuchasePage({
                            source: "TokenSourceLowBalanceCommonToast",
                            roomType: "public",
                            target: buttonRef.current!,
                        })
                        if (closeToast) {
                            closeToast()
                        }
                    }}
                />
            </div>
        </div>
    )
}
