import type { InputHTMLAttributes } from "react"
import { useId } from "react"
import "./InputToggle.scss"
import { mergeClasses } from "../../../../utils/css"

interface InputToggleProps extends InputHTMLAttributes<HTMLInputElement> {
    checked: boolean
    onToggle: (isOn: boolean) => void
    className?: string
}

export function InputToggle({
    checked,
    onToggle,
    className,
    ...props
}: InputToggleProps) {
    const uniqueId = useId()
    return (
        <div className={mergeClasses("InputToggle", className)}>
            <label
                className="InputToggle__switch"
                htmlFor={`InputToggle__checkbox_${uniqueId}`}
            >
                <input
                    type="checkbox"
                    id={`InputToggle__checkbox_${uniqueId}`}
                    onChange={(e) => onToggle(e.target.checked)}
                    checked={checked}
                    {...props}
                />
                <div className="InputToggle__slider round" />
            </label>
        </div>
    )
}
