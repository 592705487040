import { createRoot } from "react-dom/client"
import { SharedToastContainer } from "../components/common/organisms/SharedToastContainer"
import { performToggleIsActive } from "../hooks/appContext"

declare global {
    interface Window {
        showToast: ({
            message,
            type,
            containerId,
        }: {
            message: string
            type: "success" | "error" | "info" | "warning"
            containerId?: string
        }) => void
    }
}

export const showToast = (args: {
    message: string
    type?: "success" | "error" | "info" | "warning"
    containerId?: string
}) => {
    if (
        !performToggleIsActive("TknRflTopUp") ||
        window.showToast === undefined
    ) {
        return
    }
    window.showToast({ ...args, type: args.type ?? "success" })
}

export const injectToastContainer = () => {
    if (!performToggleIsActive("TknRflTopUp")) {
        return
    }
    // Global toast trigger
    if (window.showToast === undefined) {
        window["showToast"] = (args) => {
            const event = new CustomEvent(args.containerId ?? "global-toast", {
                detail: args,
            })
            document.dispatchEvent(event)
        }
    }

    const containerId = "global-toast"
    let container = document.getElementById(containerId)

    if (!container) {
        container = document.createElement("div")
        container.id = containerId
        document.body.appendChild(container)
    }

    const root = createRoot(container)
    root.render(<SharedToastContainer containerId="global-toast" />)
}
