import { useEffect } from "react"
import { isMobile } from "react-device-detect"
import { toast, ToastContainer } from "react-toastify"
import { AppContextProvider } from "../../../../hooks/appContext"
import { FlaggedI18nProvider } from "../../../../i18n"
import { mergeClasses } from "../../../../utils/css"
import { CommonLowBalanceToast } from "./CommonLowBalanceToast"
import { PrivateLowBalanceToast } from "./PrivateLowBalanceToast"
import type { ToastContainerProps, TypeOptions } from "react-toastify"
import "./SharedToastContainer.scss"

interface CustomEventDetails extends ToastContainerProps {
    message: string
    type: TypeOptions | CustomToastTypes
    containerId?: string
    onClick: () => void
    numTokens?: string
}

type CustomToastTypes =
    | "low-balance-common"
    | "low-balance-private"
    | "success-autorefill"

export const SharedToastContainer = ({
    containerId,
    className = "",
    style,
    storyMode,
}: {
    containerId: string
    className?: string
    style?: React.CSSProperties
    storyMode?: boolean
}) => {
    useEffect(() => {
        const handleShowToast = (event: CustomEvent<CustomEventDetails>) => {
            const {
                message,
                type = "success",
                containerId = "global-toast",
                onClick,
                numTokens,
                ...other
            } = event.detail

            if (type === "success-autorefill") {
                toast(message, {
                    type: "success",
                    containerId,
                    className: "success-autorefill",
                    ...other,
                })
                return
            }

            if (type === "low-balance-private") {
                toast(<PrivateLowBalanceToast openPuchasePage={onClick} />, {
                    toastId: "low-balance-private",
                    type: "success",
                    containerId: isMobile
                        ? "global-toast"
                        : "video-player-toast-container",
                    autoClose: false,
                    className: "low-balance-private",
                    ...other,
                })
                return
            }

            if (type === "low-balance-common") {
                toast(
                    <CommonLowBalanceToast
                        openPuchasePage={onClick}
                        numTokens={numTokens ?? "0"}
                    />,
                    {
                        containerId,
                        toastId: "low-balance-common",
                        className: "low-balance-common",
                        autoClose: false,
                        ...other,
                    },
                )
                return
            }
            // Use react-toastify to display the toast
            toast(message, { type, containerId, ...other })
        }

        document.addEventListener(
            containerId || "global-toast",
            handleShowToast as EventListener,
        )

        return () => {
            document.removeEventListener(
                containerId || "global-toast",
                handleShowToast as EventListener,
            )
        }
    }, [containerId])

    // just for storybook, since it was not working with otherwise
    if (storyMode) {
        return (
            <ToastContainer
                style={style}
                position="top-center"
                containerId={containerId}
                hideProgressBar
                pauseOnFocusLoss
                pauseOnHover
                autoClose={3000}
                className={mergeClasses(
                    "SharedToastContainer",
                    isMobile ? "mobile" : "desktop",
                    className,
                )}
            />
        )
    }
    return (
        <AppContextProvider context={window as any}>
            <FlaggedI18nProvider>
                <ToastContainer
                    style={style}
                    position="top-center"
                    containerId={containerId}
                    hideProgressBar
                    pauseOnFocusLoss
                    pauseOnHover
                    autoClose={3000}
                    className={mergeClasses(
                        "SharedToastContainer",
                        isMobile ? "mobile" : "desktop",
                        className,
                    )}
                />
            </FlaggedI18nProvider>
        </AppContextProvider>
    )
}
