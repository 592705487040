import type { ComponentPropsWithoutRef } from "react"
import "./PurchaseSuccessPage.scss"
import { t } from "@lingui/macro"
import { isMobile } from "react-device-detect"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { Link, Typography } from "../../common"
import TokenPurchaseSuccess from "../../common/atoms/Icons/Purchase/TokenPurchaseSuccess"
import { SharedToastContainer } from "../../common/organisms/SharedToastContainer"
import { AutoRefill } from "../AutoRefill"

interface PurchaseSuccessPageProps extends ComponentPropsWithoutRef<"div"> {
    tokenAmount: number
    isSupporterPlan: boolean
    shouldShowAutoRefill: boolean
    tokenPrice?: string
    returnUrl?: string
    returnToUrlName?: string
}

export function PurchaseSuccessPage({
    tokenAmount,
    isSupporterPlan,
    shouldShowAutoRefill = false,
    tokenPrice,
    returnUrl,
    returnToUrlName,
    ...props
}: PurchaseSuccessPageProps) {
    const showAutoRefill =
        shouldShowAutoRefill && !!tokenPrice && !isSupporterPlan

    return (
        <div className="PurchaseSuccessPage" {...props}>
            <div className="PurchaseSuccessPage__icon">
                <TokenPurchaseSuccess />
            </div>
            <Typography
                weight={Weights.bold}
                size={isMobile ? Sizes.lgpx : Sizes.mdpx}
                className="PurchaseSuccessPage__title"
            >{t`Purchase Successful!`}</Typography>
            <Typography
                size={isMobile ? Sizes.smpx : Sizes.xspx}
                className="PurchaseSuccessPage__message"
            >
                {isSupporterPlan
                    ? t`Supporter was added to your account.`
                    : tokenAmount
                      ? t`${tokenAmount} tokens added. They’ll appear in your account within 10 minutes.`
                      : ""}
            </Typography>
            {showAutoRefill && (
                <AutoRefill numTokens={tokenAmount} price={tokenPrice} />
            )}
            {returnUrl && (
                <div className="PurchaseSuccessPage__link">
                    <Link href={returnUrl}>
                        {`Return to ${returnToUrlName || returnUrl}`}
                    </Link>
                </div>
            )}
            <SharedToastContainer
                containerId="purchase-success-toast"
                className="purchase-success-toast"
                storyMode
            />
        </div>
    )
}
