import { getCookieOrEmptyString } from "@multimediallc/web-utils/storage"
import { createSlice } from "@reduxjs/toolkit"
import { createApi } from "@reduxjs/toolkit/query/react"
import { cbBaseQuery } from "../utils/myFetch"
import type { Message, Thread } from "../components/messaging/types"
import type { PayloadAction } from "@reduxjs/toolkit"

declare global {
    interface Window {
        PRODUCTION: boolean
    }
}

interface MessageResponse {
    messages: Message[]
    num_unread: number
}

export interface ThreadsResponse {
    threads: Thread[]
    has_more: boolean
}

interface ThreadsQueryParams {
    offset: number
    limit: number
}

export const DM_THREAD_PAGE_LIMIT = 20

const DEFAULT_THREAD_PARAMS: ThreadsQueryParams = {
    offset: 0,
    limit: DM_THREAD_PAGE_LIMIT,
}

interface MessagingState {
    conversationListScroll: number
}

const initialState: MessagingState = {
    conversationListScroll: 0,
}

export const messagingSlice = createSlice({
    name: "messaging",
    initialState,
    reducers: {
        setConversationListScroll: (state, action: PayloadAction<number>) => {
            state.conversationListScroll = action.payload
        },
    },
})

export const { setConversationListScroll } = messagingSlice.actions
export default messagingSlice.reducer

interface PublishMessageRequest {
    message: string
    to_user: string
    from_user: string
}

// REMOVE_IN_PRODUCTION: Helper function to add test data
const addTestData = (thread: Thread): Thread => {
    return {
        ...thread,
        other_user: {
            ...thread.other_user,
            isOnline: Math.random() > 0.5,
            isLive: Math.random() > 0.6,
            inFanclub: Math.random() > 0.7,
            tokensSpent:
                Math.random() > 0.7
                    ? Math.floor(Math.random() * 10000) + 100
                    : undefined,
            isMuted: Math.random() > 0.5,
            avatarUrl:
                Math.random() > 0.5
                    ? `https://picsum.photos/seed/${thread.other_user.username}/40/40`
                    : undefined,
        },
    }
}

export const messagingApi = createApi({
    reducerPath: "messagingApi",
    tagTypes: ["History"],
    baseQuery: cbBaseQuery({
        baseUrl: "/api/messaging/",
    }),
    endpoints: (builder) => ({
        getUnread: builder.query<any, void>({
            query: () => ({
                url: "unread/",
                method: "GET",
            }),
        }),
        getThreads: builder.query<
            ThreadsResponse,
            { offset: number; limit: number }
        >({
            query: ({ offset, limit }) => ({
                url: `threads/?offset=${offset}&limit=${limit}`,
                method: "GET",
            }),
            transformResponse: (response: ThreadsResponse) => ({
                ...response,
                threads: window.PRODUCTION
                    ? response.threads
                    : response.threads.map(addTestData),
            }),
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            merge: (currentCache, newItems, { arg }) => {
                if (arg.offset === 0) {
                    return newItems
                }

                const mergedThreads = [...currentCache.threads]

                newItems.threads.forEach((newThread) => {
                    const existingIndex = mergedThreads.findIndex(
                        (thread) =>
                            thread?.other_user?.username ===
                            newThread?.other_user?.username,
                    )
                    if (existingIndex !== -1) {
                        mergedThreads[existingIndex] = newThread
                    } else {
                        mergedThreads.push(newThread)
                    }
                })

                return {
                    threads: mergedThreads,
                    has_more: newItems.has_more,
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.offset !== previousArg?.offset
            },
        }),
        publishMessage: builder.mutation<void, PublishMessageRequest>({
            query: (data) => ({
                url: "publish/",
                method: "POST",
                data: {
                    csrfmiddlewaretoken: getCookieOrEmptyString("csrftoken"),
                    message: JSON.stringify({ m: data.message }),
                    to_user: data.to_user,
                    from_user: data.from_user,
                },
            }),
        }),
        getHistory: builder.query<
            MessageResponse,
            { username: string; offset?: string }
        >({
            query: ({ username, offset }) => ({
                url: `history/${username}/${offset ? `?offset=${offset}` : ""}`,
                method: "GET",
            }),
            serializeQueryArgs: ({ queryArgs }) => {
                return queryArgs.username
            },
            merge: (currentCache, newItems, { arg }) => {
                if (arg.offset) {
                    return {
                        messages: [
                            ...newItems.messages,
                            ...currentCache.messages,
                        ],
                        num_unread: currentCache.num_unread,
                    }
                }
                return newItems
            },
            forceRefetch: ({ currentArg, previousArg }) => {
                return currentArg?.offset !== previousArg?.offset
            },
            providesTags: (result, error, { username }) => [
                { type: "History" as const, id: username },
            ],
        }),
        getProfile: builder.query<any, string>({
            query: (otherUser) => ({
                url: `profile/${otherUser}/`,
                method: "GET",
            }),
        }),
        markAsRead: builder.mutation<void, string>({
            query: (username) => ({
                url: "last-seen/",
                method: "POST",
                data: {
                    from_usernames: username,
                },
            }),
            async onQueryStarted(username, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(updateThreadUnread(username, 0))

                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
        }),
    }),
})

type UpdateQueryDataResult = ReturnType<
    typeof messagingApi.util.updateQueryData
>

export const upsertThread = (
    newThread: Thread,
    isFromMe: boolean,
): UpdateQueryDataResult => {
    return messagingApi.util.updateQueryData(
        "getThreads",
        DEFAULT_THREAD_PARAMS,
        (draft) => {
            const existingThreadIdx = draft.threads.findIndex(
                (thread) =>
                    thread?.other_user?.username ===
                    newThread?.other_user?.username,
            )
            if (existingThreadIdx !== -1) {
                const [existingThread] = draft.threads.splice(
                    existingThreadIdx,
                    1,
                )
                newThread.num_unread = isFromMe
                    ? existingThread?.num_unread || 0
                    : (existingThread?.num_unread || 0) + 1
            }
            draft.threads.unshift(newThread)
            return draft
        },
    )
}

export const updateThreadUnread = (
    username: string,
    numUnread: number,
): UpdateQueryDataResult => {
    return messagingApi.util.updateQueryData(
        "getThreads",
        DEFAULT_THREAD_PARAMS,
        (draft) => {
            const thread = draft.threads.find(
                (t) => t?.other_user?.username === username,
            )
            if (thread) {
                thread.num_unread = numUnread
            }
            return draft
        },
    )
}

export const addMessageToHistory = (
    username: string,
    newMessage: Message,
): UpdateQueryDataResult => {
    return messagingApi.util.updateQueryData(
        "getHistory",
        { username },
        (draft) => {
            draft.messages.push(newMessage)
            return draft
        },
    )
}

export const {
    useGetUnreadQuery,
    useGetThreadsQuery,
    useLazyGetThreadsQuery,
    usePublishMessageMutation,
    useGetHistoryQuery,
    useGetProfileQuery,
    useMarkAsReadMutation,
} = messagingApi
