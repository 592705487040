import type { ComponentPropsWithoutRef } from "react"
import { useCallback, useState } from "react"
import "./PurchasePage.scss"
import { gettext } from "@multimediallc/web-utils"
import { usePerformToggle } from "../../../hooks/appContext"
import { useGlobalEventListener } from "../../../hooks/globalEventListener"
import { useFocusTrap } from "../../../hooks/useTrapFocus"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
    selectPaymethod,
    setPage,
    useGetPurchaseDataQuery,
    useMakeOneClickPurchaseMutation,
    useMakePurchaseMutation,
} from "../../../store/purchasePage/purchasePageSlice"
import { mergeClasses } from "../../../utils/css"
import { OneClickFlowErrorPage } from "../../chat/OneClickFlowErrorPage"
import { OneClickFlowSuccessPage } from "../../chat/OneClickFlowSuccessPage"
import { Button, ButtonColor, Link, Spinner, Typography } from "../../common"
import { ShowMoreList } from "../../common/molecules/ShowMoreList"
import {
    PAYMETHOD_CREDITCARD,
    PAYMETHOD_CRYPTOPAYMENT,
    PAYMETHOD_EPOCH_ONECLICK,
    PAYMETHOD_SAVECARD,
    SUGGEST_OTHER_PAYMETHODS_LINK,
} from "../constants"
import { CvvPage } from "../CvvPage"
import { PurchaseMethodDescription } from "../PurchaseMethodDescription"
import { PurchaseMethodRow } from "../PurchaseRow"
import { PurchaseSuccessPage } from "../PurchaseSuccessPage"
import { SavedCardDeleteModal } from "../SavedCardDeleteModal"
import { TokenPlans } from "../TokenPlans"
import {
    getProperPaymentPlans,
    isAlternativePaymethod,
    shouldShowCheckoutButton,
    shouldShowTokenPlans,
} from "../utils"
import type {
    PaymentPlan,
    Paymethod,
    SavedCardPayMethod,
} from "../../../store/purchasePage/purchasePageSlice"

const i18n = {
    purchaseTokens: gettext("Purchase Tokens"),
    suggestOtherPaymethods: gettext("Suggest Another Payment Method"),
    tokenPackages: gettext("Token Packages"),
    morePaymentOptions: gettext("More Payment Options"),
    paymentOptions: gettext("Payment Options"),
    completePurchase: gettext("Complete Purchase"),
    continueToCheckout: gettext("Continue to checkout"),
}

const generateSavedCardProps = (
    card: SavedCardPayMethod,
    handleOpenCardDeleteModal: (id: string) => void,
) => ({
    last4: card.cc_last_4,
    cardType: card.card_type,
    expiresAt: card.cc_expire_string,
    id: card.id,
    handleOpenCardDeleteModal: () => handleOpenCardDeleteModal(card.uuid),
})

interface PurchasePageProps extends ComponentPropsWithoutRef<"form"> {
    openPurchasePage: () => void
    source: string
    closeModal: () => void
    desktop: boolean
    type?: "modal" | "page"
}

function PlansContainer({
    selectedPayMethod,
    tokenPlans,
    onGoBack,
    showAllPlans,
    setShowAllPlans,
}: {
    selectedPayMethod: Paymethod
    tokenPlans: PaymentPlan[]
    onGoBack: () => void
    showAllPlans: boolean
    setShowAllPlans: (showAllPlans: boolean) => void
}) {
    const page = useAppSelector((state) => state.purchasePage.page)

    if (page === "cvv") {
        return <CvvPage onGoBack={onGoBack} />
    }

    if (shouldShowTokenPlans(selectedPayMethod.type)) {
        return (
            <TokenPlans
                showAllPlans={showAllPlans}
                onShowAllPlans={() => setShowAllPlans(true)}
                tokenPlans={tokenPlans}
                selectedPayMethod={selectedPayMethod}
            />
        )
    }

    return <PurchaseMethodDescription selectedPayMethod={selectedPayMethod} />
}

export function PurchasePage({
    source,
    closeModal,
    desktop,
    type = "modal",
}: PurchasePageProps) {
    const isTokenAutorefillActive = usePerformToggle("TknRflTopUp")?.active

    const [trapFocusRef] = useFocusTrap()
    // listen for messages from the iframe for creditcard form
    useGlobalEventListener(
        "message",
        window,
        (event) => {
            const ev = event as MessageEvent

            if (
                ![
                    "purchase-success",
                    "purchase-declined",
                    "purchase-declined-epoch",
                ].includes(ev.data)
            ) {
                return
            }

            if (ev.data === "purchase-success") {
                dispatch(setPage("success"))
            }
            if (ev.data === "purchase-declined") {
                dispatch(setPage("error"))
            }
            if (ev.data === "purchase-declined-epoch") {
                dispatch(setPage("error-epoch"))
            }
        },
        true,
    )
    const dispatch = useAppDispatch()
    const [showAllPayMethods, setShowAllPayMethods] = useState(false)
    const [showAllPlans, setShowAllPlans] = useState(false)

    const [secondaryLoading, setSecondaryLoading] = useState(false)

    const isLoading = useAppSelector((state) => state.purchasePage.loading)
    const page = useAppSelector((state) => state.purchasePage.page)
    const [savedCardToDelete, setSavedCardToDelete] = useState<string | null>()
    const [purchaseRedirectUrl, setPurchaseRedirectUrl] = useState<
        string | null
    >(null)
    const [makeOneClickPurchase] = useMakeOneClickPurchaseMutation()
    const [makePurchase] = useMakePurchaseMutation()

    // getting purchase data
    const { refetch: refetchPurchaseData } = useGetPurchaseDataQuery({
        source: source,
    })

    const payMethods = useAppSelector((state) => state.purchasePage.payMethods)

    const purchaseError = useAppSelector((state) => state.purchasePage.error)
    const cryptoError = useAppSelector(
        (state) => state.purchasePage.cryptoError,
    )
    const selectedPayMethod = payMethods.find((p) => p.is_default)
    const isOneClickEligible =
        selectedPayMethod?.type !== undefined &&
        [PAYMETHOD_SAVECARD, PAYMETHOD_EPOCH_ONECLICK].includes(
            selectedPayMethod.type,
        )
    const purchasePlans = useAppSelector(
        (state) =>
            state.purchasePage.paymentPlans[
                getProperPaymentPlans(selectedPayMethod?.type ?? "") ??
                    "creditCard"
            ],
    )
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (!selectedPayMethod) {
            return
        }
        const formData = new FormData(e.currentTarget as HTMLFormElement)
        const cvv = formData.get("cvv") as string

        if (
            selectedPayMethod?.type === "savedcard" &&
            (selectedPayMethod as SavedCardPayMethod).cvv2_required &&
            !cvv
        ) {
            dispatch(setPage("cvv"))
            return
        }
        const selectedPurchasePlan = purchasePlans.find((p) => p.is_default)
        // saved card cvv provided or not needed

        if (selectedPayMethod?.type === "savedcard") {
            if (!selectedPurchasePlan) {
                return
            }

            await makeOneClickPurchase({
                cvv2: cvv,
                subId: (selectedPayMethod as SavedCardPayMethod).id,
                productId: selectedPurchasePlan.product_id,
            })
            return
        }

        const numTokens = formData.get("numTokens") as string
        const email = formData.get("email") as string

        // we need a refference to new window prior doing request, so browser will know it is done by user
        let newWindowOpen: WindowProxy | null = null

        const isPage = type === "page"

        const shouldOpenInNewWindow = () => {
            if (isPage) {
                return false
            }
            if (desktop) {
                if (selectedPayMethod.type === PAYMETHOD_CREDITCARD) {
                    return false
                }

                return true
            }
            return true
        }

        if (shouldOpenInNewWindow()) {
            newWindowOpen = window.open("", "_blank", "height=800,width=800")
        }

        setSecondaryLoading(true)
        await makePurchase({
            payMethod: selectedPayMethod.type,
            ...(selectedPayMethod.type === PAYMETHOD_CRYPTOPAYMENT
                ? { numTokens, email }
                : {}),
            ...(shouldShowTokenPlans(selectedPayMethod.type)
                ? {
                      [isAlternativePaymethod(selectedPayMethod.type)
                          ? "productIDAlternativePayments"
                          : "productID"]: selectedPurchasePlan?.product_id,
                  }
                : {}),
        })
            .unwrap()
            .then((data) => {
                setTimeout(() => {
                    setSecondaryLoading(false)
                }, 1000)
                const redirect_url = data.redirect_url
                if (shouldOpenInNewWindow()) {
                    newWindowOpen!.location = redirect_url
                    closeModal()
                    return
                }
                // on actual purchase page jsut use redirects
                if (isPage) {
                    window.location.href = redirect_url
                    return
                }
                // we will show iframe only for our credit card form for now on a desktop
                setPurchaseRedirectUrl(redirect_url)
                dispatch(setPage("iframe"))
            })
            .catch(() => {
                setSecondaryLoading(false)
                newWindowOpen?.close()
            })
    }

    const handleOpenCardDeleteModal = useCallback((id: string) => {
        setSavedCardToDelete(id)
    }, [])

    const makeSureSelectedPayMethodVisible = (payMethod: Paymethod) => {
        if (payMethod.is_default && !payMethod.is_initial) {
            return { ...payMethod, is_initial: true }
        }
        return payMethod
    }

    if (["error", "error-epoch"].includes(page)) {
        return (
            <div className="cb-purchase-page error">
                <div className="cb-purchase-page__content no-pad">
                    <OneClickFlowErrorPage
                        openPurchasePage={() => {
                            void refetchPurchaseData()
                            dispatch(setPage("main"))
                        }}
                        tryEpochMessage={page === "error-epoch"}
                    />
                </div>
            </div>
        )
    }
    if (page === "success") {
        const selectedPurchasePlan = purchasePlans.find((p) => p.is_default)
        return (
            <div className="cb-purchase-page success">
                <div className="cb-purchase-page__content no-pad">
                    {isTokenAutorefillActive ? (
                        <PurchaseSuccessPage
                            tokenAmount={
                                Number(selectedPurchasePlan?.tokens) ?? "0"
                            }
                            isSupporterPlan={
                                selectedPurchasePlan?.tokens === "200S"
                            }
                            shouldShowAutoRefill={true}
                            tokenPrice={`${selectedPurchasePlan?.symbol}${selectedPurchasePlan?.cost}`}
                        />
                    ) : (
                        <OneClickFlowSuccessPage
                            tokenAmount={
                                Number(selectedPurchasePlan?.tokens) ?? "0"
                            }
                            isSupporterPlan={
                                selectedPurchasePlan?.tokens === "200S"
                            }
                        />
                    )}
                </div>
            </div>
        )
    }

    if (page === "iframe") {
        return (
            <div className="cb-purchase-page">
                <div
                    className={mergeClasses(
                        "cb-purchase-page__content",
                        "no-pad",
                        "iframe",
                    )}
                >
                    <div className="cb-purchase-page__backWrapper">
                        <span className="cb-purchase-page__back-arrow">
                            &#8249;{" "}
                        </span>{" "}
                        <Button
                            color={ButtonColor.Link}
                            text={i18n.paymentOptions}
                            onClick={() => void dispatch(setPage("main"))}
                            className="cb-purchase-page__back-button"
                        />
                    </div>
                    <iframe
                        title="purchase-iframe"
                        src={purchaseRedirectUrl ?? ""}
                        height={800}
                        width={450}
                        style={{ border: "none" }}
                    />
                </div>
            </div>
        )
    }

    if (isLoading || !selectedPayMethod || secondaryLoading) {
        return (
            <div className="cb-purchase-page">
                <div className="cb-purchase-page__content">
                    <div className="cb-purchase-page__content-loading">
                        <div className="cb-purchase-page__loading">
                            <Spinner />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <form
            className={mergeClasses(
                "cb-purchase-page",
                !shouldShowCheckoutButton(selectedPayMethod.type)
                    ? "hiddenCheckoutButton"
                    : "",
            )}
            onSubmit={(e) => void handleSubmit(e)}
            ref={trapFocusRef as React.RefObject<HTMLFormElement>}
        >
            {savedCardToDelete && (
                <SavedCardDeleteModal
                    cardData={
                        payMethods.find(
                            (m) => m.uuid === savedCardToDelete,
                        ) as SavedCardPayMethod
                    }
                    closeModal={() => {
                        setSavedCardToDelete(null)
                    }}
                />
            )}
            <Typography
                className="cb-purchase-page__title"
                size="lgpx"
                weight="bold"
            >
                {i18n.purchaseTokens}
            </Typography>
            <div className="cb-purchase-page__content">
                {purchaseError && (
                    <Typography
                        size="smpx"
                        className="cb-purchase-page__error-container"
                        dangerouslySetInnerHTML={{ __html: purchaseError }}
                    />
                )}
                {cryptoError && (
                    <Typography
                        size="smpx"
                        className="cb-purchase-page__error-container"
                        dangerouslySetInnerHTML={{ __html: cryptoError }}
                    />
                )}
                {page === "main" ? (
                    <ShowMoreList
                        showMoreClicked={showAllPayMethods}
                        onShowMoreClicked={() => setShowAllPayMethods(true)}
                        className="cb-purchase-page__payMethods"
                        options={payMethods
                            .map(makeSureSelectedPayMethodVisible)
                            .map((key) => ({
                                id: key.uuid,
                                isInitial: key.is_initial,
                                label: (
                                    <PurchaseMethodRow
                                        key={key.uuid}
                                        id={key.uuid}
                                        checked={key.is_default}
                                        disabled={key.disabled}
                                        purchaseMethod={key.type}
                                        label={key?.label}
                                        onChange={() => {
                                            dispatch(selectPaymethod(key.uuid))
                                        }}
                                        savedCardProps={
                                            key.type === "savedcard"
                                                ? generateSavedCardProps(
                                                      key as SavedCardPayMethod,
                                                      handleOpenCardDeleteModal,
                                                  )
                                                : undefined
                                        }
                                    />
                                ),
                            }))}
                        showMoreText={i18n.morePaymentOptions}
                        initialShowMoreFilter={(opts) =>
                            Boolean(opts?.isInitial)
                        }
                        renderAfterShowMoreClicked={
                            <div className="cb-purchase-page__showMoreWrapper">
                                <Link
                                    href={SUGGEST_OTHER_PAYMETHODS_LINK}
                                    target="_blank"
                                >
                                    {i18n.suggestOtherPaymethods}
                                </Link>
                            </div>
                        }
                    />
                ) : (
                    <PurchaseMethodRow
                        key={selectedPayMethod.uuid}
                        id={selectedPayMethod.uuid ?? ""}
                        checked={true}
                        disabled={selectedPayMethod.disabled}
                        purchaseMethod="savedcard"
                        onChange={() => {}}
                        savedCardProps={generateSavedCardProps(
                            selectedPayMethod as SavedCardPayMethod,
                            handleOpenCardDeleteModal,
                        )}
                    />
                )}

                <PlansContainer
                    showAllPlans={showAllPlans}
                    setShowAllPlans={setShowAllPlans}
                    selectedPayMethod={selectedPayMethod}
                    tokenPlans={purchasePlans}
                    onGoBack={() => {
                        dispatch(setPage("main"))
                    }}
                />
            </div>

            {shouldShowCheckoutButton(selectedPayMethod.type) && (
                <div className="continueToCheckoutWrapper">
                    <Button
                        type="submit"
                        className="cb-purchase-page__checkoutButton"
                        text={
                            isOneClickEligible
                                ? i18n.completePurchase
                                : i18n.continueToCheckout
                        }
                    />
                </div>
            )}
        </form>
    )
}
