import "./TokenPlans.scss"
import { type ComponentPropsWithoutRef } from "react"
import { gettext } from "@multimediallc/web-utils"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { selectPaymentPlan } from "../../../store/purchasePage/purchasePageSlice"
import { Typography } from "../../common"
import { InfoIconTooltip } from "../../common/molecules/InfoIconTooltip"
import { ShowMoreList } from "../../common/molecules/ShowMoreList"
import { DISPLAY_CUTOFF_TEXT, MAX_TOKENS_DISPLAY_CUTOFF } from "../constants"
import { PurchaseTokenPlanRow } from "../PurchaseRow"
import type {
    PaymentPlan,
    Paymethod,
} from "../../../store/purchasePage/purchasePageSlice"

const i18n = {
    tokenPackages: gettext("Token Packages"),
    moreTokenPackages: gettext("More Token Packages"),
}

interface TokenPlansProps extends ComponentPropsWithoutRef<"div"> {
    tokenPlans: PaymentPlan[]
    selectedPayMethod: Paymethod
    showAllPlans: boolean
    onShowAllPlans: () => void
}
export function TokenPlans({
    showAllPlans,
    onShowAllPlans,
    selectedPayMethod,
    tokenPlans,
    ...props
}: TokenPlansProps) {
    const dispatch = useAppDispatch()
    const tokenCouldPurchaseToday = useAppSelector(
        (state) => state.purchasePage.tokensAllowedForPurchase,
    )

    let tokenDisplayText = tokenCouldPurchaseToday.toString()
    if (tokenCouldPurchaseToday > MAX_TOKENS_DISPLAY_CUTOFF) {
        tokenDisplayText = DISPLAY_CUTOFF_TEXT
    }
    return (
        <div className="TokenPlans" {...props}>
            <Typography size="smpx" className="TokenPlans__title-sec">
                {i18n.tokenPackages}:{" "}
                <InfoIconTooltip
                    tooltipClassName="TokenPlans__tooltip"
                    text={`Tokens you can purchase today: ${tokenDisplayText}`}
                    divotProps={{
                        direction: "left",
                        top: "-2px",
                        left: "15px",
                        divotBgTop: "1px",
                        divotBgLeft: "1px",
                    }}
                />
            </Typography>
            <ShowMoreList
                showMoreClicked={showAllPlans}
                onShowMoreClicked={onShowAllPlans}
                className="TokenPlans__tokenPlans"
                options={tokenPlans.map((key) => ({
                    ...key,
                    id: key.product_id,
                    isInitial: key.is_initial,
                    label: (
                        <PurchaseTokenPlanRow
                            checked={key.is_default}
                            tokenPackage={key}
                            onChange={() => {
                                dispatch(
                                    selectPaymentPlan({
                                        payMethod: selectedPayMethod.type,
                                        productId: key.product_id,
                                    }),
                                )
                            }}
                        />
                    ),
                }))}
                showMoreText={i18n.moreTokenPackages}
                initialShowMoreFilter={(opts) => Boolean(opts?.isInitial)}
            />
        </div>
    )
}
