import { type ComponentPropsWithoutRef, useState } from "react"
import "./AutoRefill.scss"
import { t, Trans } from "@lingui/macro"
import { useAppContext } from "../../../hooks/appContext"
import { Sizes, Weights } from "../../../interfaces/sizing"
import { showToast } from "../../../utils/sharedToast"
import { Link, Typography } from "../../common"
import { InputToggle } from "../../common/atoms/InputToggle"

interface AutoRefillProps extends ComponentPropsWithoutRef<"div"> {
    numTokens: number
    price: string
}

export function AutoRefill({ numTokens, price, ...props }: AutoRefillProps) {
    const isMobile = useAppContext()?.context?.is_mobile
    const userName = useAppContext()?.context?.logged_in_user?.username
    const [checked, setChecked] = useState(false)

    const handleToggle = () => {
        setChecked(!checked)
        // async logic to add after design finishes
        const status = checked ? "deactivated" : "activated"
        showToast({
            containerId: "purchase-success-toast",
            type: "success",
            message: t`Auto refill ${status}`,
        })
    }

    const minTokenThreshold = numTokens / 10 // 10% of last token purchase

    return (
        <div className="AutoRefill" {...props}>
            <div className="AutoRefill__header">
                <Typography weight={Weights.bold} size={Sizes.smpx}>
                    {t`Activate Auto Refill`}
                </Typography>
                <InputToggle onToggle={handleToggle} checked={checked} />
            </div>
            <Typography
                size={isMobile ? Sizes.smpx : Sizes.xspx}
                className="AutoRefill__middle"
            >
                <Trans>
                    When your account balance falls below{" "}
                    <Typography
                        component="span"
                        weight={Weights.bold}
                        size={isMobile ? Sizes.smpx : Sizes.xspx}
                    >
                        {minTokenThreshold} tokens
                    </Typography>
                    , it will be refilled with{" "}
                    <Typography
                        component="span"
                        weight={Weights.bold}
                        size={isMobile ? Sizes.smpx : Sizes.xspx}
                    >
                        {numTokens} tokens - {price}
                    </Typography>
                    <Typography
                        size={isMobile ? Sizes.smpx : Sizes.xspx}
                        className="AutoRefill__last"
                    >
                        {isMobile ? (
                            "You can turn it off in the top-left menu."
                        ) : (
                            <>
                                You can manage it anytime in your{" "}
                                <Link href={`/p/${userName}/?tab=settings`}>
                                    account settings.
                                </Link>
                            </>
                        )}
                    </Typography>
                </Trans>
            </Typography>
            <div />
        </div>
    )
}
