import { createSlice } from "@reduxjs/toolkit"
import type { RootState } from "./store"
import type { PayloadAction } from "@reduxjs/toolkit"

interface User {
    username: string
    token_balance?: number
}

export interface UserState {
    languageCode: string
    loggedInUser?: User
}

const initialState: UserState = {
    languageCode: "en", // this should be overwritten by the preloadedState in store.ts
}

export const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        updateTokenBalance: (state, action: PayloadAction<number>) => {
            if (state.loggedInUser) {
                state.loggedInUser.token_balance = action.payload
            }
        },
    },
})

export const { updateTokenBalance } = userSlice.actions

export const selectTokenBalance = (state: RootState): number | undefined =>
    state.user.loggedInUser?.token_balance

export default userSlice.reducer
